import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Breadcrumb, BreadcrumbItem } from "../components/Breadcrumb";
import PageContainer from "../components/PageContainer";
import Slider from "react-slick";
import { Left, Right } from "../components/SliderNavigation";
import { parse } from "date-fns";
import {
    Container,
    PageTitle2,
    SliderContainer,
    StyledImg,
    ContentsContainer,
    TitleContainer,
    TitleWrapper,
    Title,
    KoreanTitle,
    KoreanTitleSm,
    Info,
    OneLineDescription,
    Description,
    InfoContainer,
    Poster,
    InfoWrapper,
    InfoBox,
    InfoTitle,
    InfoContents,
    VideoWrapper,
    Video,
    WatchWrapper,
    WatchTime,
    StyledCard,
    ImageWrapper,
    City,
    ItalicDescription,
    DotIcon,
} from "./filmDetailStyles";
import {
    ModalPopup,
    OfflineSchedulePopup,
} from "../components/OfflineSchedulePopup";
import unique from "../utils/unique";
import { InlineLink } from "../components/guests/styles";

export default ({ data }) => {
    const film = data.filmsJson;
    const poster = data.poster?.childImageSharp.fluid;
    const snap1 = data.snap1?.childImageSharp.fluid;
    const snap2 = data.snap2?.childImageSharp.fluid;
    const snap3 = data.snap3?.childImageSharp.fluid;
    const snap4 = data.snap4?.childImageSharp.fluid;
    const snap5 = data.snap5?.childImageSharp.fluid;
    const social = data.social?.childImageSharp.resize.src;
    const socialWidth = data.social?.childImageSharp.resize.width;
    const socialHeight = data.social?.childImageSharp.resize.height;

    const {
        id,
        breadCrumb,
        linkTo,
        title,
        koreanTitle,
        info,
        note,
        oneLineDescription,
        description,
        director,
        cast,
        genre,
        distributor,
        descriptionItalic,
        videoUrl,
        schedule,
        awards,
    } = film;

    const slideSettings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: "linear",
        autoplay: false,
        autoplaySpeed: 7000,
        pauseOnHover: false,
        prevArrow: <Left />,
        nextArrow: <Right />,
    };

    const link = "/films/" + id;

    const loadCity = () => {
        const cities = schedule
            .sort((a, b) => {
                const screeningDateA = parse(
                    a.screeningDate,
                    "yyyy-MM-dd HH:mm",
                    new Date()
                );
                const screeningDateB = parse(
                    b.screeningDate,
                    "yyyy-MM-dd HH:mm",
                    new Date()
                );
                if (screeningDateA < screeningDateB) return -1;
                if (screeningDateA > screeningDateB) return 1;
                return 0;
            })
            .map(item => item.city)
            .filter(unique)
            .map(item => ({
                city: item,
                image: data[item.toLowerCase()].childImageSharp.fixed,
            }));

        return (
            <>
                {cities.map(item => {
                    return (
                        <ModalPopup
                            key={item.city}
                            trigger={
                                <StyledCard>
                                    <ImageWrapper>
                                        <Img fixed={item.image} />
                                    </ImageWrapper>
                                    <City>{item.city}</City>
                                </StyledCard>
                            }
                            modal
                            nested
                        >
                            {close => (
                                <OfflineSchedulePopup
                                    schedule={schedule}
                                    city={item.city}
                                    title={title}
                                    close={close}
                                />
                            )}
                        </ModalPopup>
                    );
                })}
            </>
        );
    };

    return (
        <Layout>
            <SEO
                title={title}
                image={{
                    metaImage: {
                        src: social,
                        width: socialWidth,
                        height: socialHeight,
                    },
                }}
            />
            <PageContainer>
                <Breadcrumb>
                    <BreadcrumbItem Link="/films" Text="Film Line-Up" />
                    <BreadcrumbItem Link={link} Text={breadCrumb} />
                </Breadcrumb>
                <PageTitle2>{title}</PageTitle2>
                <KoreanTitleSm>{koreanTitle}</KoreanTitleSm>
                <Container>
                    <SliderContainer>
                        <Slider {...slideSettings}>
                            {!!snap1 ? <StyledImg fluid={snap1} /> : null}
                            {!!snap2 ? <StyledImg fluid={snap2} /> : null}
                            {!!snap3 ? <StyledImg fluid={snap3} /> : null}
                            {!!snap4 ? <StyledImg fluid={snap4} /> : null}
                            {!!snap5 ? <StyledImg fluid={snap5} /> : null}
                        </Slider>
                    </SliderContainer>

                    <ContentsContainer>
                        <TitleContainer>
                            <TitleWrapper>
                                <Title>{title}</Title>
                                <KoreanTitle>{koreanTitle}</KoreanTitle>
                            </TitleWrapper>
                        </TitleContainer>
                        <Info>{info}</Info>
                        <OneLineDescription>{note}</OneLineDescription>
                        <OneLineDescription>
                            "{oneLineDescription}"
                        </OneLineDescription>
                        <Description>{description}</Description>
                        <ItalicDescription>
                            {descriptionItalic}
                        </ItalicDescription>
                        {awards && (
                            <InfoWrapper>
                                <InfoContents>
                                    Awards and Invitation
                                </InfoContents>
                                {awards.map(award => (
                                    <InfoBox key={award}>
                                        <DotIcon />
                                        <InfoContents>{award}</InfoContents>
                                    </InfoBox>
                                ))}
                            </InfoWrapper>
                        )}
                        <InfoContainer>
                            {!!poster ? <Poster fluid={poster} /> : null}
                            <InfoWrapper>
                                <InfoBox>
                                    <InfoTitle>Director</InfoTitle>
                                    <InfoContents>
                                        {director}{" "}
                                        {title &&
                                        ["DOG DAYS", "FAQ", "PICNIC"].includes(
                                            title
                                        ) ? (
                                            <>
                                                {" - "}
                                                <InlineLink to="/festival-guests">
                                                    Q&A with Director
                                                </InlineLink>
                                                {" after movie"}
                                            </>
                                        ) : undefined}
                                    </InfoContents>
                                </InfoBox>

                                <InfoBox>
                                    <InfoTitle>
                                        {id === "red-shoes-and-the-seven-dwarfs"
                                            ? "Voice Cast"
                                            : "Cast"}
                                    </InfoTitle>
                                    <InfoContents>{cast}</InfoContents>
                                </InfoBox>

                                <InfoBox>
                                    <InfoTitle>Genre</InfoTitle>
                                    <InfoContents>{genre}</InfoContents>
                                </InfoBox>

                                <InfoBox>
                                    <InfoTitle>Distributor</InfoTitle>
                                    <InfoContents>{distributor}</InfoContents>
                                </InfoBox>
                            </InfoWrapper>
                        </InfoContainer>
                    </ContentsContainer>
                    <VideoWrapper>
                        <Video url={videoUrl} />
                    </VideoWrapper>
                </Container>
            </PageContainer>
        </Layout>
    );
};

export const pageQuery = graphql`
    fragment FilmImage on File {
        childImageSharp {
            fluid(maxWidth: 2400) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    fragment SocialImage on File {
        childImageSharp {
            resize(width: 1200) {
                src
                width
                height
            }
        }
    }
    fragment CityImg on File {
        childImageSharp {
            fixed(width: 60, grayscale: false) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
        }
    }
    fragment CityImgSmall on File {
        childImageSharp {
            fixed(width: 40, grayscale: false) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
        }
    }
    query(
        $id: String!
        $posterFilePath: String!
        $snap1FilePath: String!
        $snap2FilePath: String!
        $snap3FilePath: String!
        $snap4FilePath: String!
        $snap5FilePath: String!
    ) {
        filmsJson(id: { eq: $id }) {
            id
            breadCrumb
            linkTo
            title
            koreanTitle
            info
            note
            oneLineDescription
            description
            descriptionItalic
            director
            cast
            genre
            distributor
            videoUrl
            schedule {
                screeningDate
                city
                venue
                bookingLink
            }
        }
        poster: file(relativePath: { eq: $posterFilePath }) {
            ...FilmImage
        }
        snap1: file(relativePath: { eq: $snap1FilePath }) {
            ...FilmImage
        }
        snap2: file(relativePath: { eq: $snap2FilePath }) {
            ...FilmImage
        }
        snap3: file(relativePath: { eq: $snap3FilePath }) {
            ...FilmImage
        }
        snap4: file(relativePath: { eq: $snap4FilePath }) {
            ...FilmImage
        }
        snap5: file(relativePath: { eq: $snap5FilePath }) {
            ...FilmImage
        }
        social: file(relativePath: { eq: $snap1FilePath }) {
            ...SocialImage
        }
        sydney: file(relativePath: { eq: "flaticon/png/004-sydney.png" }) {
            ...CityImg
        }
        brisbane: file(
            relativePath: { eq: "flaticon/png/002-brisbane-city-hall.png" }
        ) {
            ...CityImgSmall
        }
        melbourne: file(
            relativePath: { eq: "flaticon/png/003-melbourne.png" }
        ) {
            ...CityImgSmall
        }
        canberra: file(relativePath: { eq: "flaticon/png/001-canberra.png" }) {
            ...CityImg
        }
    }
`;

import styled, { css } from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { AiOutlineFieldTime } from "react-icons/ai";
import { MdOutlineSubtitles, MdOutlineAppRegistration } from "react-icons/md";
import { BsCameraVideo, BsDot } from "react-icons/bs";
import { Link } from "gatsby";

export const Container = styled.div`
    padding-top: 0;
    padding-bottom: 0;
    @media (min-width: ${breakpoints.sm}) {
        padding-top: 2rem;
        padding-bottom: 4rem;
    }
`;
export const ContentsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-betw;
    padding: 2rem 0 2rem 0;
    margin: 2rem 0 6rem 0;
    border-top: 1px #eee solid;
    border-bottom: 1px #eee solid;
    max-width: 100%;
`;
export const Title = styled.h2`
    padding-bottom: 1rem;
`;
export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    font-size: 0.9rem;

    @media (min-width: ${breakpoints.lg}) {
        flex-flow: row;
        font-size: 1rem;
    }
`;

export const IconStyle = css`
    color: ${colors.primary};
    font-size: 2.5rem;
    // margin-top: 0.5rem;
    margin-right: 1rem;
    width: 4rem;
`;

export const TimeIcon = styled(AiOutlineFieldTime)`
    ${IconStyle}
`;
export const VideoIcon = styled(BsCameraVideo)`
    ${IconStyle}
`;
export const TitleIcon = styled(MdOutlineSubtitles)`
    ${IconStyle}
`;

export const RegistrationIcon = styled(MdOutlineAppRegistration)`
    ${IconStyle}
`;
export const DotIcon = styled(BsDot)`
    font-size: 2.5rem;
    height: 1.25rem;
`;
export const ContentsWarpper = styled.div`
    display: flex;
    justify-content: flex-start;
`;
export const IconWarpper = styled.div`
    color: ${colors.primary};
    font-size: 2.5rem;
    margin-right: 1rem;
    width: 100%;
`;
export const Info = styled.p`
    font-size: 1.5rem;
    padding-bottom: 2rem;

    margin: 0;
    @media (min-width: ${breakpoints.sm}) {
        max-width: none;
    }
`;
export const InfoWarpper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px #eee;
`;

export const InfoBox = styled.div`
    margin: 0 auto 0 0;
    width: 100%;
    @media (min-width: ${breakpoints.sm}) {
        min-width: 17rem;
        margin: 0;
    }
`;
export const InfoNote = styled.p`
    font-size: 1.2rem;
    padding-bottom: 0.5rem;
    margin: 1.5rem 0;
    @media (min-width: ${breakpoints.sm}) {
        max-width: none;
    }
`;
export const InfoContents = styled.p`
    padding: 0;
    margin: 0;
`;
export const InfoTitle = styled.p`
    padding: 0;
    margin: 0;
    width: 100%;
    font-weight: 600;
`;
export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
`;

export const Name = styled.p`
    font-weight: 600;
    font-size: 1.2rem;
`;
export const InfoContentsWrapper = styled.div`
    width: 100%;
`;

export const AnchorButton = styled.a`
    cursor: pointer;
`;

export const ImageWrapper = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
    @media (min-width: ${breakpoints.sm}) {
        width: 30rem;
        max-height: 22rem;
        margin-bottom: 0;
        margin-right: 3rem;
        margin-top: 0.4rem;
    }
`;

export const PersonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 3rem 0;
    width: 100%;
    @media (min-width: ${breakpoints.sm}) {
        flex-direction: row;
    }
`;

export const Contents = styled.p`
    width: 100%;
    white-space: break-spaces;
`;
export const ContentsWrapper = styled.div`
    width: 100%;
`;
export const InlineLink = styled(Link)`
    color: ${colors.dark};
    &:hover {
        color: ${colors.primary};
    }
`;
